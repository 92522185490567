import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { getSettings, updateSetting } from "../../helpers/fakebackend_helper"

const CMSWebSettings = props => {
  const [isApiExecuting, setIsApiExecuting] = useState(true)
  const [settings, setSettings] = useState({
    expressInterestsId: undefined,
    expressInterests: 30,
  })

  const getAppSetting = async () => {
    try {
      const response = await getSettings()
      if (response?.success) {
        let update = {}
        response?.data?.map(item => {
          if (item.key === "express-interests") {
            update["expressInterests"] = parseInt(item?.value)
            update["expressInterestsId"] = item?.id
          }
        })
        setSettings(prevState => ({ ...prevState, ...update }))
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
    } catch (e) {
      toast.error(e.response.data.error)
    } finally {
      setIsApiExecuting(false)
    }
  }

  const updateAppSetting = async e => {
    try {
      e.preventDefault()
      setIsApiExecuting(true)
      const payload = {
        id: settings.expressInterestsId,
        value: settings.expressInterests,
      }
      const response = await updateSetting(payload)
      if (response?.success) {
        setIsApiExecuting(false)
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
    } catch (e) {
      toast.error(e.response.data.error)
    } finally {
      setIsApiExecuting(false)
    }
  }

  useEffect(() => {
    getAppSetting()
  }, [])

  useEffect(() => {
    if (!isApiExecuting) {
      const handler = setTimeout(() => {
        console.log("handler-->", settings.expressInterests)
        if (
          !settings?.expressInterests ||
          (settings?.expressInterests &&
            parseInt(settings?.expressInterests) < 1)
        ) {
          setSettings(prevState => ({
            ...prevState,
            ...{ expressInterests: "1" },
          }))
        }
      }, 1000)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [settings.expressInterests])

  const onInputChange = e => {
    let update = {}
    update[e.target.name] = e.target.value
    if (e.target.name === "expressInterests") {
      const re = /^[0-9\b]+$/
      if (e.target.value === "" || re.test(e.target.value)) {
        setSettings(prevState => ({ ...prevState, ...update }))
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Settings" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <div className="col-lg-4">
                          <Label
                            htmlFor="express-interests"
                            className="col-form-label col-lg-8"
                          >
                            Express Interest Time Limit (days)
                          </Label>
                          <Col lg="8">
                            <Input
                              disabled={isApiExecuting}
                              id="expressInterests"
                              name="expressInterests"
                              type="number"
                              placeholder="Enter Express Intrest"
                              value={settings.expressInterests}
                              onChange={onInputChange}
                            />
                          </Col>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <Label
                            htmlFor="express-interests"
                            className="col-form-label col-lg-8"
                          ></Label>
                          <Col lg="8">
                            <Button
                              onClick={updateAppSetting}
                              color="primary"
                              type="submit"
                              className="btn-rounded p-4 pb-2 pt-2"
                            >
                              Save
                            </Button>
                          </Col>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CMSWebSettings
